import React from 'react';
import FullscreenVideo from './FullscreenVideo';

function App() {
  return (
    <div className="App">
      <FullscreenVideo />
      {/* Other components can go here */}
    </div>
  );
}

export default App;
