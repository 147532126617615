import React, { useState, useRef } from 'react';
import './index.css';

const FullscreenVideo = () => {
  const [showOverlay, setShowOverlay] = useState(true);
  const videoRef = useRef(null);

  const handleOverlayClick = () => {
    setShowOverlay(false);
    videoRef.current.play();
  };

  return (
    <div>
      {showOverlay && (
        <div className="video-overlay" onClick={handleOverlayClick} style={{position: 'absolute', zIndex: 9999}}>
          Click for fish
        </div>
      )}
      <video ref={videoRef} loop id="myVideo">
        <source src="video.mp4" type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
    </div>
  );
};

export default FullscreenVideo;
